<template>
  <div>
    <ul class="list-group list-group-flush list my--3" v-loading="loading">
      <li
        v-for="venue in recommendations"
        :key="venue.id + 'recommendation'"
        class="list-group-item px-0 has-unavailability-buttons has-upload"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <span class="avatar">
              <img alt="Image placeholder" :src="venue.thumbnail" />
              <venue-availability v-if="isCorporate" :lead-event="leadEvent" :venue="venue" :isVenueUnavailable="venue.isVenueUnavailable" @update="venueAvailabilityStatusChanged"/>
            </span>
          </div>
          <div class="col details">
            <h5 class="mb-0">{{ venue.name }}
                <i v-if="venue.isLiked" class="fas fa-heart pl-1 text-danger"></i>
                <i v-if="venue.isNotPreferred" class="fas fa-xmark-large pl-1 text-danger"></i>
                <span class="venue-booked-by-lead" v-if="venue.isDirectlyBookedByLead"><b>Marked Booked</b></span>
                <span class="quote-requested-by-lead" v-if="venue?.isQuoteRequested"><b>Quote Requested</b></span>
            </h5>
            <badge rounded :type="badgeStatusType(venue.recommendation)">
              {{ venue.recommendation }}
            </badge>
            <upload-quote
              class="float-right"
              v-if="(isCorporate || venue?.isQuoteUploaded)"
              :lead-event="leadEvent"
              :venue="venue"
              :upload-pending="venue?.isQuoteRequested"
              :quote-uploaded="venue?.isQuoteUploaded"
              :quote-last-uploaded="venue?.quoteLastUploaded"
              :quote-downloaded="venue?.isQuoteDownloaded"
              @update="quoteFileUpdated"
            ></upload-quote>
          </div>
        </div>
      </li>
    </ul>
    <p class="text-center mt-5" v-if="recommendations.length == 0">
      No Recommended Venues
    </p>
  </div>
</template>
<script>

import UploadQuote from "@/views/Widgets/LeadEventDetails/UploadQuote";
import EnquiryService from "@/services/enquiry.service";
import VenueAvailability from '@/views/Widgets/LeadEventDetails/VenueAvailability';
export default {
  name: "venue-recommendation",
  components: {
    VenueAvailability,
    UploadQuote
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    recommendations() {
      return this.$store.getters["recommendations/getRecommendationsByLeadEventId"](
        this.leadEvent.id
      );
    },
    isCorporate() {
      return this.leadEvent.event_type == EnquiryService.EVENT_TYPE_CORPORATE;
    }
  },
  async created() {
    this.loadRecommendations();
  },
  methods: {
    badgeStatusType(status) {
      let badge = 'secondary';
      switch (status && status.toLowerCase()) {
        case "sent to contact":
          badge = 'info';
          break;
        default:
          break;
      }
      return badge;
    },
    async loadRecommendations() {
      this.loading = true;
      await this.$store.dispatch(
        "recommendations/getRecommendations",
        this.leadEvent.id
      );
      this.loading = false;
    },
    quoteFileUpdated() {
      this.loadRecommendations();
    },
    venueAvailabilityStatusChanged() {
      this.loadRecommendations();
    }
  }
};
</script>

<style scoped>
li:hover .btn-remove {
  visibility: visible;
  opacity: 1;
  transition: opacity, 0.8s;
}

li .venue-booked-by-lead,
li .quote-requested-by-lead {
    background-color: black;
    color: white;
    cursor: default;
    padding: 4px 6px;
    border-radius: 4px;
    float: right;
}

/* .karen-status-container #karen_status_dropdown {
  float: right;
} */

</style>
