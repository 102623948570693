<template>
  <div :class="`col-sm-` + (fullWidth ? '12 full-width' : '10')">
    <div class="row">
      <div class="col">
        <h3 class="mb-0">
          {{ leadEvent.name }} <el-tag size="mini">{{ leadEvent.event_type }}</el-tag>
          <a
            v-if="fullWidth" href="javascript:"  class="btn btn-link btn-sm float-right" id="show-timeline"
            @click="timeline">
            Timeline
          </a>
        </h3>
        
      </div>
    </div>
    <div class="row mt-2 sub-details">
      <div class="col">
        <i class="ni ni-calendar-grid-58 mr-2"></i>
        {{leadEvent.event_start | moment("DD MMM YYYY")}}
      </div>
      <div class="col">
        <i class="fa-light fa-users mr-2"></i>
        {{leadEvent.estimated_guests}} PAX
      </div>
      <div class="col">
        <i class="fa-light fa-circle-dollar mr-2"></i>
        {{ formattedEstimatedBudget }}
      </div>
    </div>
    <el-dialog
      title="Timeline"
      width="1000px"
      v-loading="loading"
      :visible.sync="showLeadEventTimeline"
      v-if="showLeadEventTimeline"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <LeadEventTimeline :lead-event-id="leadEvent.id"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import LeadEventTimeline from "@/views/Widgets/LeadEventDetails/LeadEventTimeline";
import { formatCurrency } from '@/util/formatCurrency';
import { mapState } from "vuex";
import { Tag } from "element-ui";
export default {
  components: {
    [Tag.name]: Tag,
    LeadEventTimeline,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    fullWidth: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLeadEventTimeline: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    formattedEstimatedBudget: function() {
      return this.leadEvent.estimated_budget ? formatCurrency(this.country, this.leadEvent.estimated_budget) : this.leadEvent.estimated_budget;
    },
  },
  methods: {
    timeline() {
      this.showLeadEventTimeline = true;
    },
  },
};
</script>
<style scoped>
.sub-details {
    color: #bfbfbf;
    font-size: 13px;
}
.full-width .sub-details {
  color: #6f6f6f;
}
</style>