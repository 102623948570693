<template>
  <div class="content">
    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <router-link :to="{ name: 'LeadDetails', params: { id: leadId }}">
              <h1 class="display-3 text-white">{{ lead.first_name }} {{lead.last_name}}</h1>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-7">
          <template>
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0" id="lead_details_heading">
                    Lead Details
                    <badge
                      type="warning" class="ml-2" v-if="lead.is_unsubscribed" >
                      <span
                        @click="resubscribe"
                        @mouseover="unsubscribeText = 'Re-Subscribe User?'"
                        @mouseleave="unsubscribeText = 'Unsubscribed'">{{unsubscribeText}}
                      </span>
                    </badge>
                    <badge type="success" class="ml-2" v-else >Subscribed</badge>
                  </h3>
                </div>
                <div class="col-4 text-right">
                  <a href="javascript:" v-if="!isEditing" class="btn btn-sm btn-danger" @click="deleteLead">
                    Delete
                  </a>
                  <a href="javascript:" v-if="!isEditing" class="btn btn-sm btn-primary lead-edit" @click="isEditing = true; oldEmail = lead.email">Edit</a>
                  <a href="javascript:" v-if="isEditing" class="btn btn-sm btn-primary lead-save" @click="saveEditedLead">Save</a>
                  <a href="javascript:" v-if="isEditing" class="btn btn-sm btn-danger lead-cancel" @click="isEditing = false;">Cancel</a>
                </div>
              </div>
              <form v-loading="loading" id="leadinformation">
                <div v-if="lead.first_name">
                  <h6 class="heading-small text-muted mb-4">
                    Lead Information
                  </h6>
                  <div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          :disabled="leadInputDisabled"
                          type="text"
                          label="First Name"
                          id="lead_information_first_name"
                          placeholder="First Name"
                          v-model="lead.first_name"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          :disabled="leadInputDisabled"
                          type="text"
                          label="Last Name"
                          id="lead_information_last_name"
                          placeholder="Last Name"
                          v-model="lead.last_name"
                        >
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          :disabled="leadInputDisabled"
                          type="email"
                          label="Email Address"
                          id="lead_information_email"
                          placeholder="mike@email.com"
                          v-model="lead.email"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          :disabled="leadInputDisabled"
                          type="phone"
                          label="Phone Number"
                          id="lead_information_phone"
                          placeholder="0400 123 123"
                          v-model="lead.phone"
                        >
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6" v-if="lead.secondary_email">
                        <base-input
                          :disabled="leadInputDisabled"
                          type="email"
                          label="Secondary Email Address"
                          id="lead_information_secondary_email"
                          placeholder="mike@email.com"
                          v-model="lead.secondary_email"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6"  v-if="lead.secondary_phone">
                        <base-input
                          :disabled="leadInputDisabled"
                          type="phone"
                          label="Secondary Phone Number"
                          id="lead_information_secondary_phone"
                          placeholder="0400 123 123"
                          v-model="lead.secondary_phone"
                        >
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <h6 class="heading-small text-muted mb-4">Enquiries</h6>
                <enquiries :lead-id="leadId"></enquiries>
              </form>
              <lead-emails :lead-id="leadId"></lead-emails>
            </card>
          </template>
        </div>
        <div class="col-xl-5">
          <lead-events :lead-id="leadId"></lead-events>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  MessageBox,
  Loading,
  Option,
  Select
} from "element-ui";
// import LeadNavbar from './LeadNavbar.vue';

import LeadEmails from '@/views/Widgets/LeadDetails/LeadEmails';
import Enquiries from '@/views/Widgets/LeadDetails/Enquiries';
import LeadEvents from '@/views/Widgets/LeadDetails/LeadEvents';
import { mapState } from "vuex";
import LeadService from '@/services/lead.service';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    LeadEmails,
    Enquiries,
    LeadEvents,
  },
  data() {
    return {
      isEditing: false,
      leadId: parseInt(this.$route.params.id),
      dateOptions: {
        format: "YYYY-MM-DD",
      },
      loading: true,
      oldEmail: '',
      unsubscribeText: 'Unsubscribed',
      event_date : "",
    };
  },
  async created() {
    await this.$store.dispatch("leads/getSingle", { id: this.leadId });
    this.loading = false;
  },
  computed: {
    lead() {
      return this.$store.getters["leads/getLeadById"](this.leadId);
    },
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    leadInputDisabled() {
      return this.isEditing ? false : true;
    },
  },
  methods: {
    resubscribe() {
      MessageBox.confirm('Are you sure?', 'Re-Subscribe', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await LeadService.resubscribe(this.leadId).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        await this.$store.dispatch("leads/getSingle", { id: this.leadId });
        this.loading = false;
      });
    },
    deleteLead() {
      MessageBox.prompt(
        "Please type `DELETE` in the input box and press Delete",
        "Are you sure to delete this lead?",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          inputPattern: /^DELETE$/,
          inputErrorMessage: "Only accept `DELETE` word",
        }
      )
        .then(({ value }) => {
          let loadingInstance = Loading.service({ fullscreen: true });
          this.$store.dispatch("leads/deleteLead", this.leadId).then(() => {
            loadingInstance.close();
            this.$router.push({ name: "AllLeads" });
          });
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    async shouldConfirmForRequeue() {
      MessageBox.confirm('Would you like to queue this lead to perform all lead actions?', 'Requeue', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await LeadService.requeue(this.leadId).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        this.loading = false;
      });
    },
    async saveEditedLead() {
      this.loading = true;
      let leadDetails = await LeadService.updateLead({
        first_name: this.lead.first_name,
        last_name: this.lead.last_name,
        email: this.lead.email,
        phone: this.lead.phone,
        event_type: this.lead.event_type,
      }, this.leadId).catch((error) => {
        if (error && error.response && error.response.status === 422) {
          this.$store.dispatch('alert/error', error.response.data.error.message, { root: true });
        } else {
          this.$store.dispatch('alert/error', error, { root: true });
        }
        return [];
      });
      this.loading = false;
      if (leadDetails && leadDetails.data && leadDetails.data.id) {
        this.$store.dispatch('alert/success', "Lead updated successfully.", { root: true });
        if (this.oldEmail !== leadDetails.data.email) {
          this.shouldConfirmForRequeue();
        }
      }
      this.isEditing = false;
    },
  },
};
</script>

<style scoped>
.btn {
  color: white;
}
.btn:hover {
  color: white;
}
</style>

